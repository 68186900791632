import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Emoji from "../components/emoji";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="work-history">
      <div className="current-role">
        <h2 className="blurb-heading">Currently</h2>
        <p className="blurb">
          <span className="job-title">Engineering Manager @</span>
          <OutboundLink
            className="work-link drift"
            href="https://www.drift.com"
          >
            Drift&nbsp;<Emoji ariaLabel="zap">⚡️</Emoji>
          </OutboundLink>
          <br />
          coaching engineers to be effective engineering leaders. Drift is
          changing the way businesses buy from businesses by using
          conversations.
        </p>
      </div>
      <div className="previous-roles">
        <h2 className="blurb-heading">Previously</h2>
        <p className="blurb">
          <span className="job-title">Engineering Manager @</span>
          <OutboundLink
            href="https://www.oreilly.com/"
            className="work-link oreilly"
          >
            O'Reilly
          </OutboundLink>
          <br />
          helping to build and coach cross functional agile teams. We're using
          lean practices to deliver a best-in-class learning platform to our
          customers.
        </p>
        <p className="blurb">
          <span className="job-title">Sr. Fullstack Engineer, Lead @</span>
          <OutboundLink href="https://xively.com/" className="work-link xively">
            Xively
          </OutboundLink>
          <br />
          working on a world class <abbr title="Internet of Things">
            IoT
          </abbr>{" "}
          platform.
        </p>
        <p className="blurb">
          <span className="job-title">Staff UI Engineer @</span>
          <OutboundLink
            href="https://www.vestmark.com/"
            className="work-link vestmark"
          >
            Vestmark
          </OutboundLink>
          <br />a leading <abbr title="Financial Technology">fintech</abbr>{" "}
          platform company.
        </p>
        <p className="blurb">
          <span className="job-title">
            Jr. UI Engineer &mdash; Sr. UI Engineer, Lead @
          </span>
          <OutboundLink
            href="https://www.wearegenuine.com/"
            className="work-link genuine"
          >
            Genuine
          </OutboundLink>
          <br />a Boston based agency we built award winning brand experiences
          that helped connect people to brands in new and interactive ways.
        </p>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
