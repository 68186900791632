import React from "react";

const Emoji = ({children, ariaLabel}) => {
  return (
    <span role="img" aria-label={`${ariaLabel} emoji`}>
      {children}
    </span>
  );
}

export default Emoji;